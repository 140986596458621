import { PropsWithChildren } from 'react'

export default function Container({children}: PropsWithChildren) {
  return (
    <div className='flex justify-center m-2'>
      <div className='max-w-[520px] sm:max-w-[640px]'>
        {children}
      </div>
    </div>
  )
}
