import {PropsWithChildren} from 'react'

interface Props extends PropsWithChildren {
  title: string;
  paragraph: string;
}

export default function ({title, paragraph, children}: Props) {
  return (
    <div>
        <div className='w-16 h-16 rounded-full flex justify-center items-center bg-[#232323]'>
          {children}
        </div>
      <h3 className='text-2xl mt-6'>{title}</h3>
      <p className='mt-4'>{paragraph}</p>
    </div>
  )
}
