import { DateCalendar, TimeClock } from '@mui/x-date-pickers'
import {Dayjs} from 'dayjs'
import React, { useState } from 'react'

export default function Book() {
  const [step, setStep] = useState(1)
  const [date, setDate] = useState<Dayjs>()
  const [time, setTime] = useState<Dayjs>()
  const [name, setName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [isNext, setIsNext] = useState(false)
  const [isLoading, setIsLoading] = useState(0);

  const updateDate: (arg0: Dayjs) => void = (value) => {
    setDate(value)
    setIsNext(true)
  }

  const updateTime: (arg0: Dayjs) => void = (value) => {
    setTime(value)
    setIsNext(true)
  }

  const updateName: (arg0: any) => void = (e) => {
    setName(e.target.value)
    if (name != "" && email != "") {
      setIsNext(true)
    }
  }

  const updateEmail: (arg0: any) => void = (e) => {
    setEmail(e.target.value)
    if (name != "" && email != "") {
      setIsNext(true)
    }
  }

  const page = () => {
    switch (step) {
      case 1:
        return (<DateCalendar disablePast value={date} onChange={updateDate}/>)
      case 2:
        return (<TimeClock value={time} onChange={updateTime} />)
      case 3:
        return (
          <div>
            <label className="input input-bordered flex items-center gap-2 mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="h-4 w-4 opacity-70">
                <path
                  d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" />
              </svg>
              <input type="text" className="grow" placeholder="Name" value={name} onChange={updateName}/>
            </label>
            <label className="input input-bordered flex items-center gap-2 mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="h-4 w-4 opacity-70">
                <path
                  d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
                <path
                  d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
              </svg>
              <input type="text" className="grow" placeholder="Email" value={email} onChange={updateEmail}/>
            </label>
          </div>
        )
      default:
        return
    }
  }

  return (
    <div className='w-[100vw] h-[100vh] flex justify-center items-center'>
      <div className='m-10 p-4 max-w-[640px] max-h-[640px] min-w-[420px] min-h-[320px] shadow-xl'>
        <ul className="steps py-6">
          <li className={`step step-info`}>Select a date</li>
          <li className={`step ${step >= 2 ? "step-info" : ""}`}>Select a time</li>
          <li className={`step ${step >= 3 ? "step-info" : ""}`}>Your information</li>
        </ul>
        {page()}
        <div className='flex flex-row-reverse'>
          <button className={`btn btn-circle btn-success ${isNext ? "" : "btn-disabled"}`}
            onClick={() => {
              if (step < 3) {
                setStep(step+1); 
                setIsNext(false);
              } else if (isLoading < 2) {
                setIsLoading(1)
                setTimeout(() => setIsLoading(2), 800)
              }
            }}
          >
            {
              isLoading === 0 ?
              <svg className='text-white' xmlns="http://www.w3.org/2000/svg" width="1em" height="2em" viewBox="0 0 12 24"><defs><path id="weuiArrowOutlined0" fill="currentColor" d="m7.588 12.43l-1.061 1.06L.748 7.713a.996.996 0 0 1 0-1.413L6.527.52l1.06 1.06l-5.424 5.425z"/></defs><use fill-rule="evenodd" href="#weuiArrowOutlined0" transform="rotate(-180 5.02 9.505)"/></svg>
              : isLoading === 1 ?
              <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-dasharray="16" stroke-dashoffset="16" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 3c4.97 0 9 4.03 9 9"><animate fill="freeze" attributeName="stroke-dashoffset" dur="0.2s" values="16;0"/><animateTransform attributeName="transform" dur="1.5s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"/></path></svg>
              : <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="2em" viewBox="0 0 48 48"><path fill="currentColor" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.55" d="m4 24l5-5l10 10L39 9l5 5l-25 25z" clip-rule="evenodd"/></svg>
            }
          </button>
        </div>
      </div>
    </div>
  )
}
