import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import Main from './pages/Main';
import Book from './pages/Book';


function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Router>
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/book' element={<Book />} />
        </Routes>
      </Router>
    </LocalizationProvider>
  );
}

export default App;
